<template>
    <v-container>
        <!-- Header -->
        <v-row class="d-flex justify-center align-center ma-2">
            <v-icon large left color="blue">mdi-package-variant-closed</v-icon>
            <div class="text-h5 pa-4 blue--text darken-2">นำเข้าเวชภัณฑ์เพิ่มเติม</div>
            <v-spacer/>
            <v-btn tile large color="blue darken-2 white--text" @click="applyRouterLink($routes.IMPORT.name)">
                <v-icon class="pr-2">mdi-package-variant-closed</v-icon>
                ยืนยันนำเข้าเวชภัณฑ์
            </v-btn>
        </v-row>
        <v-divider/>

        <!-- Import List -->
        <v-data-table :headers="importItemHeader" :items="itemExample" :footer-props="{
                'items-per-page-text' : 'จำนวนรายการที่แสดงต่อหน้า'
            }">
            <template v-slot:item.delete="{}">
                <v-btn icon outlined>
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-row class="d-flex justify-center align-center ma-2">
            <v-btn tile large color="blue darken-2 white--text" @click="addDialog = true">
                <v-icon class="pr-2">mdi-plus</v-icon>
                เพิ่มรายการ
            </v-btn>
        </v-row>

        <!-- Edit Dialog -->
        <v-dialog width="700" v-model="addDialog">
            <v-card>
                <v-card-title class="headline blue darken-2 white--text">เพิ่มรายการเวชภัณฑ์</v-card-title>
                    <!-- Edit General Info -->
                    <v-card class="pa-4">
                        <div style="max-height:700" class="mx-2">
                            <div>ชื่อสามัญ : <v-combobox :items="availableItemList"></v-combobox></div>
                            <div>ชื่อทางการค้า : <v-text-field label="กรุณาระบุชื่อทางการค้า"></v-text-field></div>
                            <div>หมายเลขล็อต : <v-text-field label="กรุณาระบุหมายเลขล็อต"></v-text-field></div>
                            <div>วันหมดอายุ : <v-text-field label="กรุณาระบุวันหมดอายุ"></v-text-field></div>
                            <div>จำนวน : <v-text-field label="กรุณาระบุจำนวน"></v-text-field></div>
                            <div>หน่วยนับ : <v-text-field label="กรุณาระบุหน่วยนับ"></v-text-field></div>
                            <div>สถานที่จัดเก็บ : <v-text-field label="กรุณาระบุสถานที่จัดเก็บ"></v-text-field></div>
                        </div>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn tile rounded color="blue darken-2 white--text" @click="addDialog = false">เสร็จสิ้น</v-btn>
                        </v-card-actions>
                    </v-card>
                    
            </v-card>

        </v-dialog>

    </v-container>
</template>
<script>
export default {
    data:()=>{
        return {
            addDialog : false,
            importItemHeader : [
                {
                    'text':'ชื่อสามัญ',
                    'value':'genericName'
                },{
                    'text':'ชื่อทางการค้า',
                    'value':'tpuName'
                },{
                    'text':'หมายเลขล็อต',
                    'value':'lotNo'
                },{
                    'text':'วันหมดอายุ',
                    'value':'expired'
                },{
                    'text':'จำนวน',
                    'value':'quantity'
                },{
                    'text':'หน่วยนับ',
                    'value':'unit'
                },{
                    'text':'สถานที่จัดเก็บ',
                    'value':'location'
                },{
                    'text':'ลบรายการ',
                    'value':'delete'
                }
            ],
            itemExample : [
                {
                    'genericName' : 'Paracetamol 500 mg',
                    'tpuName' : 'Zara',
                    'lotNo' : '1487',
                    'expired' : '8 JUL 2023',
                    'quantity' : 50,
                    'unit' : 'กล่อง',
                    'location' : 'EG-6450'
                    
                }
            ],
            availableItemList : [
                'Amoxycillin 250 mg','Amoxycillin 500 mg','Paracetamol 500 mg'
            ],
        }
    },
    methods:{
        applyRouterLink(name){
            this.$router.push({name})
        },
    },
    computed:{
    },
}
</script>